<template>
  <div class="border relative px-3 pt-2 pb-4 border-grey-0 mb-4 rounded-tl-xl rounded-br-xl">

    <!--        <div class="flex items-center justify-between">-->
    <!--          <div class="text-white  text-xl">-->
    <!--            <span class="font-bold">1.</span> <span class="">Bacon cheese burger</span> - <span-->
    <!--              class="text-secondary-0 font-bold">16,80 €</span>-->
    <!--          </div>-->
    <!--          &lt;!&ndash;          <div class="bg-danger-0  flex items-center justify-center w-[28px] h-[28px] rounded-full">&ndash;&gt;-->
    <!--          &lt;!&ndash;            <font-awesome-icon :icon="['fas','times']" class="text-white text-lg"/>&ndash;&gt;-->
    <!--          &lt;!&ndash;          </div>&ndash;&gt;-->
    <!--        </div>-->

    <div class="flex items-center justify-between">
      <div class="text-white    text-xl">
        <span class="font-bold mr-1 text-secondary-0 ">{{ props.index + 1 }}.</span>
        <span class="font-medium">{{ translateVal('title', props.item) }}</span>
        <!--        <span class="font-medium">{{ props.item.title }}</span>-->
      </div>
      <span class="text-secondary-0 text-lg font-bold">{{ meal.baseMealPrice }} €</span>
    </div>

    <div v-if="props.item.removeFromMeal" class="">
      <span class="text-danger-0 text-sm uppercase">{{ $t('removed') }}: </span>
      <span class="text-white ml-2 text-sm">{{ props.item.removeFromMeal }}</span>
    </div>

    <hr v-if="meal.hasMealExtra || meal.hasPackage" class="border-white opacity-15 mt-1">

    <template v-for="(key,i) in Object.keys(props.item.extra)">

      <MealExtra :title="props.item.extra[key].product.title"
                 :price="props.item.extra[key].product.pluItem.pluPrice"
                 :quantity="props.item.extra[key].quantity"
      />

      <MealExtra v-if="props.item.extra[key].product.foodPackaging"
                 :title="$t('meal_extra_pack')"
                 :price="props.item.extra[key].product.foodPackaging.pluPrice"
                 :quantity="props.item.extra[key].quantity"
      />

      <!--      <pre>-->
      <!--        {{props.item.extra[key].product.foodPackaging}}-->
      <!--      </pre>-->

      <MealExtra v-if="props.item.extra[key].product.deposit"
                 :title="$t('bottle_deposit')"
                 :price="props.item.extra[key].product.deposit.pluPrice"
                 :quantity="props.item.extra[key].quantity"
      />

    </template>

    <MealExtra v-if="meal.hasPackage"
               :title="$t('meal_pack')"
               :price="meal.packagePrice"
               :quantity="cartStore.itemsInCart[props.index].quantity"
    />

    <hr class="border-white opacity-15 mt-1">

    <div class="flex mt-4 items-center justify-between">
      <QuantityCounter v-model="cartStore.itemsInCart[props.index].quantity"/>
      <div class="  space-x-2 items-end flex text-lg">
        <div class="text- font-light text-white">{{ $t('sum') }}:</div>
        <div class="text-secondary-0 text-xl font-bold">{{ meal.finalPrice }} €</div>
      </div>
    </div>

    <hr class="border-white opacity-15 my-4">

    <div class="flex  justify-between">

      <div @click="editMealSideDish" class="bg-secondary-0 h-[34px] flex items-center px-3 rounded text-primary-0">
        {{ $t('edit') }}
      </div>

      <div @click="cartStore.removeFromCart(props.index)"
           class="bg-danger-0  flex items-center justify-center w-[34px] h-[34px] rounded-full">
        <font-awesome-icon :icon="['fas','times']" class="text-white text-lg"/>
      </div>
    </div>

  </div>
</template>

<script setup>
import {computed} from "vue"
import QuantityCounter from "@/components/inputs/QuantityCounter.vue"
import useCartStore from "@/stores/cartStore"
import MealExtra from "@/components/cart/MealExtra.vue";
import {useRouter} from "vue-router";
import {ROUTE_NAMES} from "@/router";
import {translateVal} from "@/plugins/l18m";

const props = defineProps({
  item: {
    type: Object,
    required: true
  },
  index: {
    type: Number,
    required: true
  }
})

const router = useRouter()
const cartStore = useCartStore()
const meal = computed(() => cartStore.calcMealPrice(props.item))

const editMealSideDish = () => router.push({
  name: ROUTE_NAMES.MEAL_SIDE_DISH,
  query: {
    cartItemIndex: props.index
  }
})

</script>