<template>
  <MasterLayout>

    <div class="flex flex-col justify-between max-h-full h-full  " style="">

      <div class="flex-1 shrink overflow-y-hidden h-full max-h-full flex flex-col relative">
        <div v-if="overFlow" class="text-white mt-8 flex items-center font-bold text-4xl">
          <span class="mr-4 italic">{{ $t('cart_title') }}</span>
        </div>

        <div v-if="!isAppActive || !isDeliveryHours" class="bg-danger-0 p-4 shrink-0 rounded-lg mt-4">

          <div class="text-center text-white text-xl font-bold">
            {{ $t('current_offline') }}
          </div>

          <hr class="my-3 bg-white opacity-25">

          <div class="text-center text-xl text-white font-semibold">
            {{ $t('contact_restaurant') }}
          </div>

          <div class="mt-2 text-xl text-center text-white">
            <a v-if="deliveryStore.deliveryPlaceSymbol === 'BO'" class=" font-medium" href="tel:+421902859136">
              +421 902 859 136
            </a>
            <a v-else class=" font-medium" href="tel:+421917110222">+421 917 110 222</a>
          </div>

          <div class="text-center text-lg  mt-2 text-white font-semibold">
            {{ $t('delivery_hours') }} <b>{{ $t('week_head') }}</b>
          </div>

          <div class="text-center text-lg  mt-4 text-white font-semibold">
            <i>Bošany</i>: {{ isoDateTimeToTime(pluStore.appSettings.deliveryFromBo) }} -
            {{ isoDateTimeToTime(pluStore.appSettings.deliveryToBo) }}
          </div>

          <div class="text-center text-lg  mt-2 text-white font-semibold">
            <i>Partizánske</i>: {{ isoDateTimeToTime(pluStore.appSettings.deliveryFromPe) }} -
            {{ isoDateTimeToTime(pluStore.appSettings.deliveryToPe) }}
          </div>

        </div>

        <div v-else-if="cartStore.cartIsEmpty"
             class="flex shrink-0 flex-1 mt-[-12vh] h-full items-center justify-center flex-col">
          <font-awesome-icon :icon="['fad', 'plate-utensils']"
                             class="text-[6rem] text-secondary-0"
          />
          <div class="text-center text-white font-bold mt-4 text-xl">
            {{ $t('no_chosen_food') }} <br/> {{ $t('in_cart') }}
          </div>

          <FloatButton @click="$router.push({name:ROUTE_NAMES.HOME_VIEW})" :icon="['fal','angle-right']"
                       class="mt-5">
            <span class="mr-12">{{ $t('go_to_pick_meal') }}</span>
          </FloatButton>

        </div>

        <div v-else class="w-full mt-4 shrink grow flex flex-col  overflow-y-scroll">

          <template v-if="cartStep === 0">

            <CartItem v-for="(item,i) in cartStore.cartItems" :item="item" :index="i" :key="i"/>

            <div v-if="!deliveryStore.isDeliveryFree"
                 class="border relative px-3 py-2  border-grey-0 mb-4 rounded-tl-xl rounded-br-xl">
              <div class="flex items-center justify-between">
                <div class="text-white    text-xl">
                  <span class="font-medium">{{ $t('cart_delivery') }}</span>
                </div>
                <span class="text-secondary-0 text-lg font-bold">{{ deliveryStore.deliveryPrice }} €</span>
              </div>
            </div>

            <div v-if="cartStore.deliveryError" class="bg-danger-0 p-4 rounded-lg mt-4">
              <div class="text-center text-white text-lg ">
                {{ cartStore.deliveryError }} !
              </div>
            </div>

          </template>

          <template v-else>

            <div v-if="overFlow" class="text-white text-xl font-light italic">
              {{ $t('payment_and_summary') }}
            </div>

            <BaseInput v-if="showAddName" v-model="nameAndSurname" class="mt-4">
              <template #label>
                {{ $t('name_and_username') }}
              </template>
            </BaseInput>

            <div v-if="!canSendOrder" class="bg-danger-0 p-3 rounded-lg mt-1">
              <div class="text-center text-white  ">
                {{ $t('insert_valid_username') }}
              </div>
            </div>

            <BaseTextArea v-model="orderState.note" class="mt-4">
              <template #label>
                {{ $t('note') }}
              </template>
            </BaseTextArea>

            <div class="font-bold mt-4 text-white text-lg">
              {{ $t('payment_options') }}
            </div>

            <OptionButton :selected="orderState.paymentMethod === PAYMENT_METHODS.cash"
                          class="mt-3"
                          :icon="['fas','wallet']"
                          @click="orderState.paymentMethod = PAYMENT_METHODS.cash"
            >
              <span>{{ $t('cash') }}</span>
            </OptionButton>

            <OptionButton :selected="orderState.paymentMethod === PAYMENT_METHODS.card"
                          class="mt-3"
                          :icon="['fas','credit-card']"
                          :disabled="false"
                          @click="orderState.paymentMethod = PAYMENT_METHODS.card">
              <span>{{ $t('card') }}</span>
            </OptionButton>

            <!--            <div class="my-1 text-white text-sm">-->
            <!--              (Dočasne nie je možné platiť kartou)-->
            <!--            </div>-->

            <hr class="opacity-25 border-white my-5">

            <div class="p-4 rounded-lg  bg-info-0">
              <font-awesome-icon :icon="['fas','circle-info']" class="text-lg text-white"/>
              <span class="text-lg text-white ml-4">{{ $t('estimate_delivery') }}  {{
                  cartStore.deliveryTimeInMinutes
                }} min.</span>
            </div>

            <div v-if="waitingForPayment"
                 class="px-4 bg-primary-0 w-screen h-screen fixed left-0 top-0 pt-[22vh] mx-auto z-120 py-2 rounded-lg">
              <div class=" flex justify-center items-center mt-5 px-2 ">
                <font-awesome-icon :icon="['fas','circle-info']" class="text-xl text-white"/>
                <span class="text-xl text-white ml-4">{{ $t('pay_waiting') }}</span>
              </div>
              <div class="px-2">
                <hr class="my-4 border-secondary-0">
              </div>

              <div class="text-center mt-2 px-2 text-white text-2xl">
                {{ $t('pay_dont_close') }}
              </div>

              <div class="text-center mt-2 px-2 text-white text-2xl">
                {{ $t('window_autoclose') }} <b class="text-secondary-0">{{ timerCounter }}</b> s
              </div>
              <Loader class="w-[140px] mt-12 h-auto mx-auto"/>
            </div>

          </template>

        </div>

      </div>

      <!--  cart footer -->
      <div v-if="!cartStore.cartIsEmpty && isAppActive && isDeliveryHours && overFlow && !waitingForPayment"
           class="shrink-0">

        <hr class="my-2 border-white opacity-25">

        <div class="flex  py-4 justify-between">

          <div v-if="cartStep === 0" class="">
            <div class="text-white text-xs opacity-85">
              {{ $t('cart_total') }}
            </div>
            <div class="text-secondary-0 font-bold text-3xl">
              {{ cartStore.sumAllCartItemsPlusDelivery }} €
            </div>
          </div>

          <BackButton v-else @click="cartStep = 0"/>

          <FloatButton v-if="cartStep === 0"
                       @click="goToNextStep"
                       :icon="['fal','angle-right']"
                       :disabled="!!cartStore.deliveryError"
          >
            <span class="mr-12">{{ $t('next') }}</span>
          </FloatButton>

          <FloatButton v-else
                       @click="sendOrder"
                       :disabled="!canSendOrder"
                       :loading="sendingOrder"
                       :icon="['fal','angle-right']"
          >
            <span class="mr-12">{{ $t('cart_send') }}</span>
          </FloatButton>

        </div>
      </div>

    </div>


  </MasterLayout>
</template>

<script setup>
import {watch, ref, computed, reactive, onUnmounted} from "vue"
import MasterLayout from "@/layouts/MasterLayout.vue";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import useCartStore, {ORDER_STATUS} from "@/stores/cartStore";
import CartItem from "@/components/cart/CartItem.vue";
import FloatButton from "@/components/buttons/FloatButton.vue";
import router, {ROUTE_NAMES} from "@/router";
import useDeliveryStore from "@/stores/deliveryStore";
import BackButton from "@/components/buttons/BackButton.vue";
import usePluStore from "@/stores/pluStore";
import BaseTextArea from "@/components/inputs/BaseTextArea.vue";
import {isoDateTimeToTime} from "@/helpers/DateTime";
import OptionButton from "@/components/buttons/OptionButton.vue";
import useSerStore from "@/stores/userStore";
import BaseInput from "@/components/inputs/BaseInput.vue";
import Supabase from "@/plugins/Supabase";
import {deleteToken, requestPermissions} from "@/plugins/capacitor/Notifications";
import {Capacitor} from "@capacitor/core";
import {Browser} from "@capacitor/browser";
import {useI18n} from "vue-i18n";

const test = ref(false)

const {t} = useI18n()
const cartStore = useCartStore()
const deliveryStore = useDeliveryStore()
const pluStore = usePluStore()
const userStore = useSerStore()

const cartStep = ref(0)

const goToNextStep = () => {
  cartStep.value++
}

const isAppActive = computed(() => {
  if (deliveryStore.deliveryPlaceSymbol === 'BO') {
    return pluStore.appSettings.appIsActiveBo && pluStore.pingBoSuccess
  }
  return pluStore.appSettings.appIsActivePe && pluStore.pingPeSuccess
})

const isDeliveryHours = computed(() => {
  if (deliveryStore.deliveryPlaceSymbol === 'BO') {
    return pluStore.isBoOpened
  }
  return pluStore.isPeOpened
})

const PAYMENT_METHODS = {
  cash: 'cash',
  card: 'card'
}

const sendingOrder = ref(false)


const nameAndSurname = ref(userStore.getUser()?.user_metadata?.nameAndUsername ?? '')

const canSendOrder = computed(() => {
// if name and username is valid if leng is min. 3 chars and include name and username it means space

  if (nameAndSurname.value.length < 3 || !nameAndSurname.value.includes(' ')) {
    return false
  }

  return true

})

const orderState = reactive({
  paymentMethod: PAYMENT_METHODS.cash,
  note: ''
  // note: 'TEST - nova aplikacia'
})

const loading = ref(false)
const payLink = ref('')
const waitingForPayment = ref(false)
const timerCounter = ref(0)
let intervalCounter = null

let orderRealTimeListener = null
const stopRealTime = () => {
  if (!orderRealTimeListener) return
  orderRealTimeListener.unsubscribe()
  orderRealTimeListener = null
}

const sleep = ms => new Promise(r => setTimeout(r, ms));

let checkEmailTimeOut = null
const onDoneClear = async (debug, clearEmailCheckTimeOut = true) => {
  stopRealTime()
  console.log('onDoneClear()', debug)
  loading.value = false
  if (checkEmailTimeOut && clearEmailCheckTimeOut) {
    clearTimeout(checkEmailTimeOut)
    checkEmailTimeOut = null
  }

  waitingForPayment.value = false
  payLink.value = ''

  try {
    await Browser.removeAllListeners()
  } catch (e) {
    console.error('Browser.removeAllListeners()', e)
  }

}


const sendOrder = async () => {

  if (loading.value) return

  loading.value = true

  await sleep(500)

  let insertedOrderToDbId = null

  const orderError = async (debug) => {
    loading.value = false
    console.log('debug', debug)
    alert(t('order_send_error'))
    await onDoneClear(1, true)
    console.log('EEE', e)
    console.log('EEE 2', insertedOrderToDbId)
    if (insertedOrderToDbId) {
      console.log('!!! DELETE_ORDER !!!')
      const {data, error} = await Supabase.from('v2_Hajcer_App_App_Orders').delete().eq('id', insertedOrderToDbId)
      console.log('dr', {
        data, error
      })
    }
  }

  try {

    const updateUserNameAndUsername = userStore.getUser()?.user_metadata?.nameAndUsername === ''
    if (updateUserNameAndUsername) {
      const {data: {user}, error} = await Supabase.auth.updateUser({
        data: {
          nameAndUsername: nameAndSurname.value.toString()
        }
      })
    }

    const user = userStore.getUser()
    const notes = orderState.note && orderState.notes !== '' ? ` Poznamka: ${orderState.note}` : ''
    const summary = `Tel.č: +${user.phone} , Meno:  ${nameAndSurname.value}, ` + deliveryStore.deliveryAddress + ' ' + notes
    const isCardPayment = orderState.paymentMethod === PAYMENT_METHODS.card

    const mapOrderForPlu = () => {

      const orderItems = []

      let counter = 1
      for (const itemInCart of cartStore.cartItems) {

        // master meal
        let note = `Číslo: ${counter}. `
        if (itemInCart.removeFromMeal) {
          note += 'Odstranene: ' + itemInCart.removeFromMeal
        }
        const singleItem = {
          note,
          pluCode: itemInCart.pluItem.pluCode,
          price: itemInCart.pluItem.pluPrice,
          amount: itemInCart.quantity,
        }

        orderItems.push(singleItem)

        // packaging
        if (itemInCart.foodPackaging) {
          orderItems.push({
            note: '',
            pluCode: itemInCart.foodPackaging.pluCode,
            price: itemInCart.foodPackaging.pluPrice,
            amount: itemInCart.quantity,
          })
        }

        // meal extra
        const extraItems = Object.keys(itemInCart?.extra) ?? []
        for (const mealExtraKey of extraItems) {

          const mealExtra = itemInCart.extra[mealExtraKey]
          const mealExtraProduct = mealExtra?.product ?? null

          if (!mealExtraProduct) continue

          const extraItem = {
            note: `Pre číslo ${counter}`,
            pluCode: mealExtraProduct.pluItem.pluCode,
            price: mealExtraProduct.pluItem.pluPrice,
            amount: mealExtra.quantity * itemInCart.quantity,
          }

          orderItems.push(extraItem)

          // packaging
          if (mealExtraProduct.foodPackaging) {
            orderItems.push({
              note: '',
              pluCode: mealExtraProduct.foodPackaging.pluCode,
              price: mealExtraProduct.foodPackaging.pluPrice,
              amount: mealExtra.quantity * itemInCart.quantity,
            })
          }

          // deposit for bottle
          if (mealExtraProduct?.deposit) {
            orderItems.push({
              note: '',
              pluCode: mealExtraProduct.deposit.pluCode,
              price: mealExtraProduct.deposit.pluPrice,
              amount: mealExtra.quantity * itemInCart.quantity,
            })
          }
        }

        counter++
      }

      // delivery item
      const deliveryPrice = Number(deliveryStore.addressDelivery.city?.pluItem?.pluPrice ?? 0)
      if (!deliveryStore.isPersonalPickUp && deliveryPrice !== 0) {
        orderItems.push({
          note: '',
          pluCode: deliveryStore.addressDelivery.city?.pluItem?.pluCode,
          price: deliveryPrice,
          amount: 1,
        })
      }

      const payType = isCardPayment ? 6 : 2

      /*
       * osobny odber 1
       * ked je rozvoz 10
       */

      const orderData = {
        "idExternalSystem": 666,
        "orderTypeId": 5,
        "payed": !isCardPayment,
        "paymentTypeId": payType,
        "customerInfo": "",
        "note": summary,
        "billCopyFromFiscal": [''],
        "transactionId": 666,
        "items": orderItems
      }

      return orderData

    }
    const mapOrderForDb = (pluOrder) => {

      const order = {
        userId: userStore.getUser()?.id,
        status: isCardPayment ? ORDER_STATUS.WAITING_FOR_PAYMENT : ORDER_STATUS.NEW,
        finalPrice: Number(cartStore.sumAllCartItemsPlusDelivery),
        deliveryType: deliveryStore.isPersonalPickUp ? 'personal' : 'delivery',
        deliveryAddress: deliveryStore.isPersonalPickUp ? deliveryStore.deliveryPlaceSymbol : (deliveryStore.addressDelivery.city?.name ?? '' + ' ' + deliveryStore.addressDelivery.street ?? ''),
        payLink: '',
        payed: false,
        note: summary,
        pluOrderId: null,
        restaurantPlace: deliveryStore.deliveryPlaceSymbol,
        pluOrder: pluOrder,
      }

      const orderItems = []

      for (const itemInCart of cartStore.cartItems) {

        const masterProduct = {
          title: itemInCart.title,
          quantity: itemInCart.quantity,
          price: itemInCart.pluItem.pluPrice,
          categoryId: itemInCart.categoryId,
          extra: []
        }

        // packaging
        if (itemInCart.foodPackaging) {
          masterProduct.extra.push({
            title: 'Obal na jedlo',
            price: itemInCart.foodPackaging.pluPrice,
            quantity: itemInCart.quantity,
          })
        }

        // meal extra
        const extraItems = Object.keys(itemInCart?.extra) ?? []
        for (const mealExtraKey of extraItems) {

          const mealExtra = itemInCart.extra[mealExtraKey]
          const mealExtraProduct = mealExtra?.product ?? null

          if (!mealExtraProduct) continue

          masterProduct.extra.push({
            title: mealExtraProduct.title,
            price: mealExtraProduct.pluItem.pluPrice,
            quantity: mealExtra.quantity * itemInCart.quantity,
          })

          // packaging
          if (mealExtraProduct.foodPackaging) {
            masterProduct.extra.push({
              title: 'Obal na prílohu',
              price: mealExtraProduct.foodPackaging.pluPrice,
              quantity: mealExtra.quantity * itemInCart.quantity,
            })
          }

          // packaging
          if (mealExtraProduct.deposit) {
            masterProduct.extra.push({
              title: 'Záloha za nápoj',
              price: mealExtraProduct.deposit.pluPrice,
              quantity: mealExtra.quantity * itemInCart.quantity,
            })
          }

        }

        orderItems.push(masterProduct)

      }

      // delivery item
      const deliveryPrice = Number(deliveryStore.addressDelivery.city?.pluItem?.pluPrice ?? 0)
      if (!deliveryStore.isPersonalPickUp && deliveryPrice !== 0) {
        orderItems.push({
          title: 'Doprava',
          price: deliveryPrice,
          quantity: 1,
        })
      }

      return {order, orderItems}

    }

    const pluOrder = mapOrderForPlu()
    const {order: dbOrder, orderItems: dbOrderItems} = mapOrderForDb(pluOrder)

    // console.log('pluOrder', pluOrder)
    // console.log('dbOrderItems', dbOrderItems)
    // return

    const {
      data: insertedOrderToDb,
      error
    } = await Supabase.from('v2_Hajcer_App_App_Orders').insert(dbOrder).select().single()

    console.log('into DB', {insertedOrderToDb, error})

    if (error) {
      alert(t('order_send_error'))
      throw 'err 1'
    }

    insertedOrderToDbId = insertedOrderToDb.id
    const mappedOrderItems = dbOrderItems.map(item => ({
      orderId: insertedOrderToDb.id,
      ...item
    }))

    const {
      error: orderDbItemsError
    } = await Supabase.from('v2_Hajcer_App_App_Orders_Items').insert(mappedOrderItems)

    if (orderDbItemsError) {
      alert(t('order_send_error'))
      console.log('!!!', 'DELETE ORDER')
      // delete order
      await Supabase.from('v2_Hajcer_App_App_Orders').delete().eq('id', insertedOrderToDb.id)
      throw 'err 2'
    }

    /*
     * If card payment
     * - get payment link
     * - redirect to payment link
     * - await for BE to update order status
     */

    if (!isCardPayment) {
      // post order to plu
      await fetch(`${import.meta.env.VITE_API_URL}/post-order-to-plu`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          newOrderId: insertedOrderToDb.id,
        })
      }).then(response => {
        console.log('response', response)
        return response.json()
      }).then(data => {
        console.log('Success:', data);
        if (data?.success) {
          alert(t('order_send_success', {id: data.pluOrderId}))
          try {
            Browser.close()
            onDoneClear(2, true)
          } catch (e) {
            console.log('e vrg', e)
          }
          cartStore.clearCart()
          router.push({
            name: ROUTE_NAMES.ORDERS_VIEW
          })
        } else {
          alert(t('order_send_error'))
          throw 'Err'
        }
      }).catch(async (error) => {
        console.error('Error:', error);
        throw error
      });

      return
    }

    fetch(`${import.meta.env.VITE_API_URL}/get-payment-url`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userId: userStore.getUser()?.id,
        dbOrderId: insertedOrderToDb.id,
      })
    }).then(response => {
      console.log('response', response)
      return response.json()
    }).then(data => {
      console.log('Success:', data);
      if (data?.success) {

        waitingForPayment.value = true
        payLink.value = data.paymentUrl

        const setUpRealTimeChannel = () => {
          orderRealTimeListener = Supabase.channel('v2_Hajcer_App_ORDER-REAL_TIME-' + insertedOrderToDb.id)
              .on(
                  'postgres_changes',
                  {
                    event: 'UPDATE',
                    schema: 'public',
                    table: 'v2_Hajcer_App_App_Orders',
                    filter: 'id=eq.' + insertedOrderToDb.id,
                  },
                  (payload) => {
                    console.log('v2_Hajcer_App_ORDER-REAL_TIME', 'UPDATE', payload)
                    if (payload.new.status === ORDER_STATUS.NEW && payload.new) {
                      onDoneClear(3, false)
                      alert(t('order_send_success', {id: payload.new.pluOrderId}))
                      cartStore.clearCart()
                      router.push({
                        name: ROUTE_NAMES.ORDERS_VIEW
                      })
                    }
                  }
              )
              .on(
                  'postgres_changes',
                  {
                    event: 'DELETE',
                    schema: 'public',
                    table: 'v2_Hajcer_App_App_Orders',
                    filter: 'id=eq.' + insertedOrderToDb.id,
                  },
                  (payload) => {
                    console.log('App settings delete', payload)
                    if (payload.new && payload.new.status === ORDER_STATUS.NEW) {
                      console.log('v2_Hajcer_App_ORDER-REAL_TIME', 'DELETE', payload)
                      alert(t('order_send_error'))
                      // stopRealTime()
                      onDoneClear(5, false)
                      // cartStore.clearCart()
                      // router.push({
                      //   name: ROUTE_NAMES.ORDERS_VIEW
                      // })
                    }
                  }
              ).subscribe()
        }
        setUpRealTimeChannel()

        /*
         * Browser was closed
         */
        Browser.addListener('browserFinished', () => {


          console.log('browserFinished')
          timerCounter.value = 30
          intervalCounter = setInterval(() => {
            timerCounter.value--
            if (timerCounter.value <= 1) {
              if (intervalCounter) {
                clearInterval(intervalCounter)
                intervalCounter = null
              }
            }
          }, 1000)

          checkEmailTimeOut = setTimeout(async () => {

            await stopRealTime()

            await sleep(500)

            await fetch(`${import.meta.env.VITE_API_URL}/check-email-payment?vs=${insertedOrderToDb.variablesymbol}`, {
              method: 'GET',
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json'
              },
            }).then(response => {
              console.log('response', response)
              return response.json()
            }).then(async data => {
              console.log('reposne:', `check-email-payment?vs=${insertedOrderToDb.variablesymbol}`, data);
              if (data?.success) {
                alert(t('order_send_success', {id: data.pluOrderId}))
                try {
                  await Browser.close()
                } catch (e) {
                  console.log('..__..')
                  console.log('Browser.close() error', e)
                }
                await onDoneClear(3, true)
                cartStore.clearCart()
                await router.push({
                  name: ROUTE_NAMES.ORDERS_VIEW
                })
              } else {
                await orderError(1)
              }
            }).catch(async (error) => {
              console.log('wľše', error)
              await orderError(2)
            })

          }, 25_000)

        })

        alert(t('dont_close_until_payment_done'))

        Browser.open({
          url: data.paymentUrl,
          presentationStyle: 'fullscreen'
        })

      } else {
        console.log('get payment link api error')
        throw 'Err'
      }

    }).catch(async (error) => {
      console.error('Error:', error);
      throw error
    })

  } catch (e) {
    await orderError(3)

  } finally {
    setTimeout(() => {
      loading.value = false
    }, 3_000)
  }

}

const canGoToNextStep = computed(() => {
  return !cartStore.cartIsEmpty && isAppActive && isDeliveryHours
})

if (!canGoToNextStep.value) {
  cartStep.value = 0
}

import {PushNotifications} from '@capacitor/push-notifications';

PushNotifications.addListener('registration', async token => {
  console.info('Registration token: ', token.value);
  if (!token.value) return
  const {data, error} = await Supabase.from('v2_Hajcer_App_Users_Fcm_Tokens').upsert({
    userId: userStore.getUser()?.id,
    token: token.value
  })

});


/// require notifications
watch(() => cartStep.value, async val => {

  if (val !== 1 || Capacitor.getPlatform().toLowerCase() === 'web') return

  await requestPermissions()

})

import {Keyboard, KeyboardResize} from '@capacitor/keyboard';
import {platform} from "@/plugins/capacitor/SafeArea";
import Loader from "@/components/Loader.vue";

const overFlow = ref(true)
if (platform === 'ios') {

  Keyboard.addListener('keyboardWillShow', (info) => {
    overFlow.value = false
  });

  Keyboard.addListener('keyboardWillHide', (info) => {
    console.log('keyboardWillHide', info)
    overFlow.value = true
  });

  Keyboard.setResizeMode({mode: KeyboardResize.Body})
  Keyboard.setScroll({isDisabled: true})
  Keyboard.setAccessoryBarVisible({isVisible: true})
}

const originName = userStore.getUser()?.user_metadata?.nameAndUsername
const showAddName = ref(true)

onUnmounted(() => onDoneClear(4, true))

</script>